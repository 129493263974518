<template>
    <template-layout>
        <template slot="content-header">
            <v-container v-if="user">
                <v-row class="fill-height" align="center">
                    <v-col class="flex-grow-0" >
                        <v-avatar color="grey" size="64">
                            <v-icon color="white">person</v-icon>
                        </v-avatar>
                    </v-col>

                    <v-col>
                        <v-row>
                            <v-col class="headline blue-grey--text text--darken-3 py-0">
                                {{ user.username }}
                            </v-col>

                            <v-col class="text--lighten-1 grey--text py-0">
                                ID: {{ user.id }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template slot="content">
            <v-container>
                <v-list dense class="t-info-list">
                    <v-list-item>
                        <v-list-item-content>Usuario</v-list-item-content>
                        <v-list-item-content>{{ user.username }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Email</v-list-item-content>
                        <v-list-item-content>{{ user.email }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Verificado</v-list-item-content>
                        <v-list-item-content>
                            <v-icon class="justify-end" small :color="user.isVerified ? 'green' : 'red'">mdi-circle</v-icon>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>Estado</v-list-item-content>
                        <v-list-item-content>
                            <v-icon class="justify-end" small :color="user.isActive ? 'green' : 'red'">mdi-circle</v-icon>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <user-permissions-card :user="user"></user-permissions-card>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import UserPermissionsCard from '@/components/User/UserPermissionsCard.vue';

    export default {
        components: {
            TemplateLayout,
            UserPermissionsCard
        },

        data() {
            return {
                user: {}
            };
        },

        computed: {},

        watch: {},

        mounted() {
            this.isLoading = true;

            this.$api.getUser(this.$route.params.id).then((user) => {
                this.user = user;
            }).finally(() => {
                this.isLoading = false;
            });
        },

        methods: {}
    };
</script>

<style lang="scss" scoped>
    .v-chip.small {
        height: 20px;
    }
</style>
