import { render, staticRenderFns } from "./UserView.vue?vue&type=template&id=fbd2027a&scoped=true&"
import script from "./UserView.vue?vue&type=script&lang=js&"
export * from "./UserView.vue?vue&type=script&lang=js&"
import style0 from "./UserView.vue?vue&type=style&index=0&id=fbd2027a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbd2027a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCol,VContainer,VIcon,VList,VListItem,VListItemContent,VRow})
