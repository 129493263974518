<template>
    <v-card v-if="user" class="mb-4">
        <v-card-title>
            Permisos
        </v-card-title>

        <v-card-text>
            <v-list dense>
                <v-list-item v-for="role in roles" :key="role">
                    <v-list-item-content>{{ role }}</v-list-item-content>
                    <v-list-item-content>
                        <v-icon v-if="hasRole(role)" color="primary">check</v-icon>
                        <v-icon v-else class="text--lighten-1 grey--text">close</v-icon>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        components: {},

        props: {
            user: {
                type: Object,
                default() {
                    return {};
                }
            }
        },

        data() {
            return {
                roles: [
                    'ROLE_USER', 'ROLE_ADMIN', 'ROLE_CHOFER', 'ROLE_HUERTA'
                ]
            };
        },

        methods: {
            hasRole(role) {
                return this.user.roles.includes(role);
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
